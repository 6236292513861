import { v4 as uuidv4 } from 'uuid';

export class OrderFileEntity {
    constructor(id, orderId, fileName, year, orderNumber, sequence, orderDate, createdAt, updatedAt, createdBy, updatedBy,
        rows, duplicatedData, currentReview, cropFamilyId, rowsInserted, rowsUpdated, insertedData, updatedData, rowsDuplicated) {

        this.id = id;
        this.orderId = orderId;
        this.fileName = fileName;
        this.year = year;
        this.orderNumber = orderNumber;
        this.sequence = sequence;
        this.orderDate = orderDate;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.rows = rows;
        this.duplicatedData = duplicatedData;
        this.currentReview = currentReview;
        this.cropFamilyId = cropFamilyId;
        this.rowsInserted = rowsInserted;
        this.rowsUpdated = rowsUpdated;
        this.insertedData = insertedData;
        this.updatedData = updatedData;
        this.rowsDuplicated = rowsDuplicated;

        this.guid = uuidv4();
    }
    static create() {
        return new OrderFileEntity(0, '', '', '', [], '', null,
            null, null, '', '', '', '', '',
            '', '', '', '', '', '');
    }

    static createFromObject(orderFile) {
        return new OrderFileEntity(orderFile.id, orderFile.orderId, orderFile.fileName, orderFile.year, orderFile.orderNumber,
            orderFile.sequence, orderFile.orderDate, orderFile.createdAt, orderFile.updatedAt, orderFile.createdBy,
            orderFile.updatedBy, orderFile.rows, orderFile.duplicatedData, orderFile.currentReview, orderFile.cropFamilyId,
            orderFile.rowsInserted, orderFile.rowsUpdated, orderFile.insertedData, orderFile.updatedData, orderFile.rowsDuplicated);
    }
}
