<template>
    <b-card>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Show" append="records">
                    <b-form-select v-model="orderFiles.itemsPerPage" id="per-page-select" size="sm" :options="orderFiles.pageOptions" style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
        </b-row>

        <b-table :items="loadData" :fields="orderFiles.fields" :filter="orderFiles.filter" :current-page="orderFiles.currentPage"
                 :per-page="orderFiles.itemsPerPage" empty-text="No records" striped bordered hover small show-empty
                 :responsive="true" :sort-by="orderFiles.sortBy" :sort-desc="orderFiles.sortDesc" ref="orderFilesTable"
                 class="text-left mb-0" @context-changed="tableContextChanged">

            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in orderFiles.fields" :key="field.key" :class="orderFiles.filters[index] && orderFiles.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="orderFiles.filters[index]">
                            <b-col v-if="orderFiles.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="orderFiles.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" @click="downloadOrderFile(row.item.fileId)" v-b-tooltip.hover title="Download" variant="outline-primary">
                        <font-awesome-icon icon="download"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Show {{ start }} to {{ end }} of {{ orderFiles.totalRows }} record{{orderFiles.totalRows !== 1 ? 's' : ''}}
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="orderFiles.currentPage" :total-rows="orderFiles.totalRows" :per-page="orderFiles.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>
<script>
import { extend } from 'vee-validate';
import { orderFilesListUrl, getImgUrl } from '@routes';
import tableStateMixin from '../../mixins/tableState';
import { required } from 'vee-validate/dist/rules';
import { OrderFileEntity } from '@/models/orderFileEntity';

extend('required', {
    ...required,
    message: 'Field required'
});
export default {
    mixins: [tableStateMixin],
    data() {
        return {
            orderFileEntity: OrderFileEntity.create(),
            array: [],
            toastTitle: 'Bulk load',
            orderFiles: {
                fields: [
                    { key: 'fileName', label: 'File name', sortable: true },
                    { key: 'year', label: 'Year', sortable: true, thStyle: 'width: 120px', class: 'text-center' },
                    { key: 'season', label: 'Season', sortable: true, thStyle: 'width:120px', class: 'text-center' },
                    { key: 'sequence', label: 'Review', sortable: true, thStyle: 'width:120px', class: 'text-center' },
                    { key: 'cropFamily', label: 'Crop family', sortable: true, class: 'text-center' },
                    { key: 'rows', label: 'Total rows', sortable: false, class: 'text-center' },
                    { key: 'rowsInserted', label: 'Rows inserted', sortable: false, class: 'text-center' },
                    { key: 'rowsDuplicated', label: 'Rows duplicated', sortable: false, class: 'text-center' },
                    { key: 'createdBy', label: 'Created by', sortable: true, class: 'text-center' },
                    { key: 'createdAt', label: 'Created', sortable: true, class: 'text-center' },
                    { key: 'updatedBy', label: 'Updated by', sortable: true, class: 'text-center' },
                    { key: 'updatedAt', label: 'Updated', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                filters: [
                    { column: 'fileName', type: 'STRING', value: '', format: '' },
                    { column: 'year', type: 'STRING', value: '', format: '' },
                    { column: 'season', type: 'STRING', value: '', format: '' },
                    { column: 'sequence', type: 'STRING', value: '', format: '' },
                    { column: 'cropFamily', type: 'STRING', value: '', format: '' }
                ],
                filter: '',
                pageOptions: [10, 25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'fileName',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Bulk load',
                path: []
            },
            cropFamily: [],
            getFile: getImgUrl()
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.orderFiles, this.tableState);
    },
    methods: {
        loadData(orderFiles) {
            if (orderFiles.sortBy !== '') {
                let data = {
                    filters: this.orderFiles.filters.filter(filter => filter.value.trim() !== ''),
                    ...orderFiles
                };
                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(orderFilesListUrl(), data).then(response => {
                    if (response.data.status === 200) {
                        this.orderFiles.totalRows = response.data.totalRows;
                        return response.data.data;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                });
            }
        },
        tableContextChanged(context) {
            this.orderFiles.sortBy = context.sortBy;
            this.orderFiles.sortDesc = context.sortDesc;
            this.saveTableState(this.$route.name, this.orderFiles);
        },
        downloadOrderFile(fileId) {
            let file = getImgUrl() + fileId;
            let xhr =  new XMLHttpRequest();

            xhr.open('HEAD', file, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        window.open(file, '_blank');
                    } else {
                        this.$bvToast.toast('Unable to download order file.', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                }
            };

            xhr.send();
        }
    },
    watch: {
        'orderFiles.filters': {
            handler() {
                this.$refs.orderFilesTable.refresh();
                this.saveTableState(this.$route.name, this.orderFiles);
            },
            deep: true
        }
    },
    computed: {
        start() {
            return this.orderFiles.totalRows === 0 ? 0 : (this.orderFiles.currentPage - 1) * this.orderFiles.itemsPerPage + 1;
        },
        end() {
            let offset = (this.orderFiles.currentPage - 1) * this.orderFiles.itemsPerPage;

            return (this.orderFiles.totalRows - offset) < this.orderFiles.itemsPerPage ? this.orderFiles.totalRows : offset + this.orderFiles.itemsPerPage;
        }
    }
};
</script>